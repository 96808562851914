(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-competition/disabled-coupon/assets/javascripts/disabled-coupon.js') >= 0) return;  svs.modules.push('/components/marketplace-competition/disabled-coupon/assets/javascripts/disabled-coupon.js');
"use strict";

const {
  ReactIcon,
  ReactButton,
  ReactPaper
} = svs.ui;
const {
  Link
} = ReactRouterDOM;
const {
  useActiveCompetitionId,
  useCompetition
} = svs.components.marketplaceCompetition.services.hooks;
const {
  resolveCompetitionName,
  resolveCompetitionUrl
} = svs.components.marketplaceCompetition.helpers;
const {
  useWagers
} = svs.components.marketplaceCompetition.services.hooks;
const {
  TipsenModuleGameUrl
} = svs.components.sport.tipsenShared;
const DisabledCoupon = _ref => {
  var _wagers$drawNumber;
  let {
    drawNumber,
    className = ''
  } = _ref;
  const activeCompetitionId = useActiveCompetitionId();
  const comp = useCompetition(activeCompetitionId);
  const compName = resolveCompetitionName(comp);
  const compUrl = resolveCompetitionUrl(comp === null || comp === void 0 ? void 0 : comp.compType);
  const wagers = useWagers(activeCompetitionId);
  const wagerId = wagers ? (_wagers$drawNumber = wagers[drawNumber]) === null || _wagers$drawNumber === void 0 ? void 0 : _wagers$drawNumber.wagerSerial : undefined;
  const urlToMyCompetitionWager = "".concat(TipsenModuleGameUrl.MY_BETS, "/").concat(wagerId, "/").concat(compUrl);
  const classNames = ['competition-disabled-coupon-paper'];
  classNames.push(className);
  return React.createElement(ReactPaper, {
    branding: "sport",
    className: classNames.join(' '),
    "data-testid": "disabled-coupon-competition"
  }, React.createElement("div", {
    className: "competition-disabled-coupon"
  }, React.createElement(ReactIcon, {
    className: "competition-disabled-coupon-icon",
    icon: "checkmark",
    size: "900"
  }), React.createElement("div", {
    className: "competition-disabled-coupon-text f-medium"
  }, "Du har redan lagt ett spel f\xF6r veckans ".concat(compName, ".")), wagerId && React.createElement(ReactButton, {
    className: "competition-disabled-coupon-button",
    "data-testid": "competition-disabled-my-bets-button",
    href: urlToMyCompetitionWager,
    inverted: true,
    size: 300
  }, "TILL MITT SPEL"), React.createElement(Link, {
    className: "qa-to-competition-lobby",
    to: {
      pathname: "tavlingar/".concat(compUrl)
    }
  }, React.createElement(ReactButton, {
    className: "competition-disabled-coupon-button",
    "data-testid": "competition-disabled-lobby-button",
    size: 300,
    transparent: true
  }, "TILL ".concat(compName.toUpperCase(), " LOBBYN")))));
};
setGlobal('svs.components.marketplaceCompetition.disabledCoupon.DisabledCoupon', DisabledCoupon);

 })(window);